import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useParams,
} from 'react-router-dom';

import database from './firebase';
import { getDatabase, ref, onValue, set } from 'firebase/database'; // Import modular functions
import TopSection from './components/TopSection';
import BottomSection from './components/BottomSection';
import SwybleIntroComponent from './components/SwybleIntroComponent';
import QuestionComponent from './components/QuestionComponent';
import LeaderBoardComponent from './components/LeaderBoardComponent';
import ComingNextComponent from './components/ComingNextComponent';
import BackgroundMusic from './components/BackgroundMusic';
import './App.css';
import LeaderboardList from './components/LeaderBoard';
import QRComponent from './components/QRComponent';
import ProgressBar from './components/ProgressBar';

function ChannelComponent() {
  const { channelId = '_main' } = useParams();
  const [pageState, setPageState] = useState(null);
  const [leaders, setLeaders] = useState([]);
  const [isBlurred, setIsBlurred] = useState(true);
  const [duration, setDuration] = useState(0);
  const [blur,setBlur] = useState(24);
  const appStyle = {
    backgroundImage: pageState?.Question?.topic?.poster
      ? `url(${pageState.Question.topic.poster})`
      : 'none',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    transition: 'backdrop-filter 0.3s ease',
  };
  useEffect(() => {
    const db = getDatabase();
    const pageStateRef = ref(db, `rooms/${channelId}`);
    const onDataChange = (snapshot) => {
      const data = snapshot.val();
      if (data && data.players) {
        const playerList = Object.values(data.players)
          .sort((a, b) => (b.score || 0) - (a.score || 0))
          .map((player, index) => ({
            rank: index + 1,
            username: player.name || 'anon',
            score: player.score || 0,
            status: player.status ||'',
            streak:player.streak||0,
            avatar: player.avatar ||'',
            coins: player.coins || 0,
          }));
        setLeaders(playerList);
      }
      console.log(data.Question);
    };
    onValue(pageStateRef, onDataChange, (error) => {
      console.error('Firebase data fetch error:', error);
    });
    const unsubscribe = onValue(pageStateRef, (snapshot) => {
      setPageState(snapshot.val());
      setIsBlurred(pageStateRef.mode === 2 || pageStateRef.mode === 3);
    });

    return () => unsubscribe();
  }, [channelId]);

  useEffect(() => {
    switch (pageState?.mode) {
      case 0:
        setDuration(10);
        setBlur(0);
        break;
      case 1:
        setDuration(5);
        setBlur(12);
        break;
      case 2:
        setDuration(15);
        setBlur(24);
        break;
      case 3:
        setDuration(5);
        break;
      case 4:
        setBlur(12);
        break;
      case 5:
        setBlur(6);
        break;
      case 6:
        setDuration(10);
        break;
      default:
        setDuration(10); // Optional: handle unexpected modes
    }
  }, [pageState]);

  if (!pageState) return <div>Loading...</div>;

  const renderComponent = () => {
    switch (pageState.mode) {
      case 0:
        return <QRComponent channelId={channelId} />;
      case 1:
        return <SwybleIntroComponent question={pageState.Question} />;
      case 2:
      case 3:
      case 4:
        return (
          <QuestionComponent
            question={pageState.Question}
            mode={pageState.mode}
          />
        );
      case 5:
        return <LeaderboardList players={leaders} />;
      default:
        return null;
    }
  };

  return (
    <div className='App' style={appStyle}>
            {(pageState.mode!==0 &&pageState.mode!==1  )&&<TopSection
        mode={pageState.mode}
        question={pageState.Question}
        channelId={channelId}
      />}
      <div className='main-content' style={{backdropFilter:`blur(${blur}px)`, transition: 'backdrop-filter 0.5s ease'}}>

        {renderComponent()}</div>
      <BottomSection
        mode={pageState.mode}
        contest={pageState.Contest}
        channelId={channelId}
      />
    </div>
  );
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/channel/:channelId' element={<ChannelComponent />} />
        <Route path='/' element={<ChannelComponent />} />
      </Routes>
    </Router>
  );
}

export default App;
