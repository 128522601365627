// BottomSection.jsx

import React, { useState, useEffect } from 'react';
import './BottomSection.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import database from '../firebase';
import { ref, onValue, off } from 'firebase/database';
import ReactCardFlip from 'react-card-flip';

const l = ['A', 'B', 'C', 'D'];


// Define the PlayerType interface (for TypeScript users)
// interface PlayerType {
//   userid: string;
//   name: string;
//   status: string;
//   optionIndexSelected: number | null;
//   time: number;
// }

function BottomSection({ mode, channelId }) {
  const [players, setPlayers] = useState([]);

  useEffect(() => {
    const roomId = channelId || "_main"; // Change as needed
    const playersRef = ref(database, `rooms/${roomId}/players`);

    const handleData = (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const playersList = Object.values(data);
        //console.log(data);
        setPlayers(playersList);
      } else {
        setPlayers([]);
      }
    };

    onValue(playersRef, handleData, (error) => {
      console.error("Error fetching players:", error);
    });

    // Cleanup listener on unmount
    return () => {
      off(playersRef, 'value', handleData);
    };
  }, []);

  return (<>{(mode!==0) && <div className="bottom-section" style={{flexDirection:'row'}}>
      <div className="center">
        <div className="avatar-container">
          {players.sort((a, b) => Number(a.inactive ?? false) - Number(b.inactive ?? false)).map((player) => {
 const avatarStyle = player.avatar ? {
  backgroundImage: `url(${player.avatar})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center'
} : {};

            const hasAnswered = typeof player.optionIndexSelected ==='number';
            const isCorrect = player.status == "correct";
            const initials = player.name.slice(0, 2).toUpperCase();
            const reveal = (mode == 3 || mode == 4 || mode==5);
            const noAvatar = !!player.avatar;
            const showCorrect = isCorrect && reveal;
            const showWrong = !isCorrect && hasAnswered && reveal;
            const showNoAnswer = !hasAnswered;
            const showAnswer = hasAnswered && !reveal;
            const isInactive = player?.inactive || false;

           var borderColor='rgba(255,255,255,0)';
            if(hasAnswered)
            {
              borderColor='yellow';
              if(reveal)
              {
              if(isCorrect)
              {
                borderColor='#00E096';
              }else{
                borderColor='red';
              }
            }
            }
            avatarStyle.borderColor= borderColor;
            console.log(player.name,isCorrect);
            return (
              <><div key={player.userid} className="avatar-wrapper" style={{opacity:isInactive?0.3:1} }>
                {<div className={'unanswered-avatar'} style={avatarStyle}>
                  <span className="avatar-initials">{!noAvatar?initials:''}</span>
                  <span className="streak-badge">{player.emoji?player.emoji:''}</span>
                  {(mode==3 || mode==4) &&<span className="a-badge" style={{backgroundColor:borderColor} }>{hasAnswered?l[player.optionIndexSelected]:''}</span>}

                </div>}
                <span className="avatar-name">{player?.name}</span>
              </div>
              </>
            );

          })}
        </div>
      </div>
    </div>}</>);
}

export default BottomSection;