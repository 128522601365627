import React from 'react';
import './SwybleIntroComponent.css'; 
import MiniWallet from './MiniWallet';
function SwybleIntroComponent({ question }) {
  return (
    <div className="card poster" style={{height:300,width:480,padding:32}}>
        <h1 class="h3 white" style={{alignItems:'center',textAlign:'center'}}>{question.category}</h1>
  <h2 class="body-text white" style={{alignItems:'center',textAlign:'center',fontWeight:200}}>{question.topic.subtitle}</h2>
   <MiniWallet size={30} balance={question.prize||0}/>
    </div>
  );
}

export default SwybleIntroComponent;